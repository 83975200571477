@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Acme&family=Cabin&family=Paytone+One&family=Rammetto+One&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

html,
body {
  overflow-x: hidden;
}
